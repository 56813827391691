.root {
  p,
  div,
  span,
  li {
    white-space: unset;

    strong {
      font-weight: 600;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  p {
    margin: 8px 0 !important;
    line-height: 28px;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ol,
  ul {
    margin: 0 0 0 24px;
    padding: 0;
    list-style-position: outside;
    list-style-type: decimal !important;

    li {
      margin: 0;
      padding: 0;
    }
  }

  ul {
    list-style-type: unset !important;
  }

  table {
    width: inherit;
    border-collapse: separate;
    border-spacing: 0;
    padding: 8px 0 16px;

    tr th,
    tr td {
      padding: 8px 16px !important;
      border-right: 1px solid var(--nj-border);
      border-bottom: 1px solid var(--nj-border);
    }

    tr th:first-child,
    tr td:first-child {
      border-left: 1px solid var(--nj-border);
    }

    tr th {
      border-top: 1px solid var(--nj-border);
      text-align: left;
    }

    tr:first-child th:first-child {
      border-top-left-radius: 8px;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 8px;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 8px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 8px;
    }
  }
}

:global(.katex-error) {
  color: var(--nj-foreground) !important;
}
